<template>
  <div class="Detail">
    <header>
      <div class="header-left">
        <img src="../assets/images/ico-back.png" alt="" @click="toHome()" />
      </div>
      <div class="header-middle">确认订单</div>
      <div class="header-right"></div>
    </header>
    <div class="container">
      <div class="card">
        <div class="card-img">
          <img :src="imgsrc" alt="" />
        </div>
        <div class="card-right">
          <div class="card-right-title"> {{ title }}</div>
          <div class="card-right-desc">单用户限购1个</div>
        </div>
      </div>
      <div class="line">
        <div class="line-left">
          礼包描述
        </div>
        <div class="card-right">
          {{ describe }}
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          购买角色
        </div>
        <div class="item-right">
          <div class="item-right-text">
            <div class="text-item" style="font-weight: bold;"> {{ password }}</div>
            <div class="text-item"><span>{{ username }}</span><span style="color: red;">(如已拥有请勿重复购买)</span></div>
          </div>
          <div class="item-right-arrow">></div>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          支付方式
        </div>
        <div class="item-right">
          <div class="item-right-text">
            <div class="text-item" style="font-weight: bold;">点券支付</div>

          </div>
          <div class="item-right-arrow">></div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer-left">
        <img src="../assets/images/ico-quan.png" alt=""><span>{{ price }}</span>
      </div>
      <div class="footer-btn" @click="handleClick">立即抢购</div>
      <van-popup v-model="show" style="width: 100%;height: 100%;">
        <div class="bdform" id="spform">
          <video id="ydvideo" src="https://chine.bqrwl.cn/hpxd/sp/gmcg.mp4" style="width: 100%;height:100%" autoplay
            :muted="false" preload="auto" autobufer="true" v-if="showVideo"></video>
          <span onclick="gbsp()" id="gbsp"> <svg t="1704340164449" class="icon" viewbox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="3172" width="64" height="64">
              <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#fofofo" p-id="3173"></path>
              <path
                d="M311.585735 246.935972m6.464976 6.464977l452.54834 452.54834q6.464976 6.464976 0 12.929952l-51.71981 51.71981q-6.464976 6.464976-12.929952 0l-452.54834-452.54834q-6.464976-6.464976 0-12.929952l51.71981-51.71981q6.464976-6.464976 12.929952 0Z"
                fill="#FFFFFF" p-id="3174"></path>
              <path
                d="M712.414265 246.935972m6.464976 6.464977l51.71981 51.71981q6.464976 6.464976 0 12.929952l-452.54834 452.54834q-6.464976 6.464976-12.929952 0l-51.71981-51.71981q-6.464976-6.464976 0-12.929952l452.54834-452.54834q6.464976-6.464976 12.929952 0Z"
                fill="#FFFFFF" p-id="3175"></path>
            </svg></span>
        </div>
        <!-- <img src="../assets/images/lqcg.mp4" alt="" style="width: 100%;height: 100%;"/>
      显示图片成功领取<img src="../assets/images/fm.png" alt="" style="width: 100%;height: 100%;"/>
       -->
      </van-popup>
    </footer>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: "DetailView",
  created() {
    this.imgsrc = this.$route.query.imgsrc
    this.title = this.$route.query.title
    this.price = this.$route.query.price
    this.describe = this.$route.query.describe
    this.password = this.$route.query.password
    this.username = this.$route.query.username
  },
  data() {
    return {
      showVideo: false,
      show: false,
      imgsrc: '',
      title: '',
      price: '',
      describe: '',
      password: '',
      username: '',
    }
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    toHome() {
      this.$router.push('/')
    },
    closeDialog() {
      this.show = false;
      this.showVideo = false
    },
    handleClick() {
      Toast.success('购买成功')
      this.show = true;
      this.showVideo = true
      const that=this
      this.$nextTick(() => {
        var myVideo = document.getElementById("ydvideo");
        myVideo.addEventListener("ended", function () {
          //执行记录视频完整观看的相应操作
          that.closeDialog()
        });
      })
      // setTimeout(() => {
      //   this.closeDialog()
      // }, 3000);
    }
  }
};
</script>
<style lang="scss" scoped>
header {
  height: 8vh;
  padding: 0 3vw;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

  .header-left {
    width: 15vw;

    img {
      vertical-align: middle;
    }
  }

  .header-middle {
    width: 70vw;
    font-weight: bold;
  }

  .header-right {
    width: 15vw;
  }

  img {
    width: 6vw;
  }
}

footer {
  height: 8vh;
  display: flex;
  justify-content: space-between;
  padding: 0 3vw;
  align-items: center;
  background-color: #fff;

  .footer-left {
    color: #FEAF12;
    display: flex;
    align-items: center;

    img {
      width: 12vw;
      vertical-align: middle;
    }

    span {
      font-weight: bold;
      font-size: 32px;
    }
  }

  .footer-btn {
    background-color: #FEAF12;
    color: #fff;
    padding: 1vh 3vh;
    border-radius: 5px;
  }
}

.container {
  padding: 0 3vw;
  height: 84vh;

  .card {
    width: 100%;
    padding: 3vw;
    height: 20vh;
    margin-bottom: 15vh;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    .card-img {
      width: 40%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-right {
      width: 57%;
      padding: 1vw 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card-right-title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .line {
    display: flex;
    margin: 3vh 0;
    align-items: center;
    justify-content: space-between;

    .line-left {
      font-weight: bold;
      // width: 35%;
    }

    .line-right {
      width: 70%;
      font-size: 13px;
      text-align: left;
    }
  }

  .item {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item-left {
      font-weight: bold;
    }

    .item-right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-right-text {
        text-align: right;
      }

      .item-right-arrow {
        margin-left: 2vw;
      }
    }
  }
}
</style>
