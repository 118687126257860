import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DetailView from "../views/DetailView.vue";
import PageView from "@/views/PageView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/order",
    name: "order",
    component: DetailView,
  },
  {
    path: "/page",
    name: "page",
    component: PageView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

//禁止F12起始
function gbsp(){
  sp.pause();
    spform.style.display="none";
  }
  
  //屏蔽F12和右键
   function click(e) {
   if (document.all) {
   if (event.button == 2 || event.button == 3) {
  alert("欢迎光临寒舍，有什么需要帮忙的话，请与站长联系！谢谢您的合作！！！");
           oncontextmenu = 'return false';
          }
        }
  if (document.layers) {
   if (e.which == 3) {
            oncontextmenu = 'return false';
          }
       }
      }
   if (document.layers) {
        document.captureEvents(Event.MOUSEDOWN);
      }
      document.onmousedown = click;
      document.oncontextmenu = new Function("return false;")
  
      document.onkeydown = document.onkeyup = document.onkeypress = function () {
   if (window.event.keyCode == 123) {
          window.event.returnValue = false;
   return (false);
        }
      }
       
  var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement("script");
   hm.src = "https:hm.baidu.com/hm.js?e8ee8b025e874cbe8c72db9389a00c7b";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();
  //禁止F12截止