<template>
  <div class="page" style="overflow-x: hidden;">
    <header>
      <div class="header-left">
        <img src="../assets/images/ico-refresh.png" alt="" />
      </div>
      <div class="header-middle">
        <div class="header-middle-title">和平集市</div>
        <div class="header-middle-desc">
          <div class="header-middle-desc-text" v-if="!isLogin">
            未绑定(请去“个人中心->游戏账号绑定”)
          </div>
          <div class="header-middle-desc-text" v-else>
            当前角色 : {{ password }}{{ username }}
          </div>
          <div class="header-middle-desc-img">
            <img src="../assets/images/ico-change.png" alt="" />
          </div>
        </div>
      </div>
      <div class="header-right">
        <van-cell class="header-right-item" @click="showPopup" style="background: none; padding: 0">
          <img src="../assets/images/ico-record.png" alt="" />
        </van-cell>
        <van-popup v-model="show">
          <van-form @submit="onSubmit" style="width: 80vw">
            <div class="title" style="margin: 1vh 0;font-weight: bold">
              绑定账号
            </div>
            <van-field v-model="username" name="user" label="平台" placeholder="平台" @click="showPicker = true"
              :rules="[{ required: true, message: '请输入平台' }]">

            </van-field>
            <van-field v-model="password" type="text" name="pwd" label="ID" placeholder="ID"
              :rules="[{ rquired: true, message: '请输入ID' }]" />
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </van-popup>
        <div class="header-right-item">
          <img src="../assets/images/ico-rule.png" alt="" />
        </div>
      </div>
    </header>

    <nav>
      <div class="nav-left">全部商品</div>
      <div class="nav-right">
        <img src="../assets/images/ico-ty-list1.png" alt="" @click="toHome()" />
      </div>
    </nav>
    <div class="container">
      <div class="card" v-for="(item, i) in dataList" :key="item.id" @click="handleClick(item)">
        <div class="card-time">
          <span>还剩</span>
          <span class="btn">{{ item.hour }}</span><span>:</span><span class="btn">{{ item.min }}</span
            class="btn"><span>:</span><span class="btn">{{ item.s }}</span>

        </div>
        <div class="card-content">
          <div class="content-left">
            <img :src="item.imgsrc" alt="">
            <div class="img-number">{{ item.id }}</div>
          </div>
          <div class="content-right">
            <div class="content-right-title">
              <div class="title-left">{{ item.title }}</div>
              <div class="title-right">
                <img src="../assets/images/ico-like-on.png" alt="">
              </div>
            </div>
            <div class="content-right-tag">{{ item.tag }}</div>
            <div class="content-right-price">
              <div class="item-price-icon">
                <img src="../assets/images/xquan.png" alt="" />
              </div>
              <div class="pirce-left"><span
                  style="color: #FEB012;font-size: 26px;font-weight: bold;margin-right: 1vw;">￥{{
                    item.price
                  }}</span><del>￥{{ item.delPrice }}</del></div>
              <div class="price-right">立即开抢</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" style="padding:20px;border-radius: 5px;">
      <van-radio-group v-model="username" @change="showPicker = false">
        <van-radio name="  微信苹果"> 微信苹果</van-radio>
        <van-radio name="  微信安卓"> 微信安卓</van-radio>
        <van-radio name="  QQ苹果"> QQ苹果</van-radio>
        <van-radio name="  QQ安卓"> QQ安卓</van-radio>
	  <!-- <van-radio name="  安卓 QQ"> 安卓 QQ</van-radio>
        <van-radio name="  安卓 VX"> 安卓 VX</van-radio>
        <van-radio name="  微信 IOS"> 微信 IOS</van-radio>
        <van-radio name="  微信 QQ"> 微信 QQ</van-radio>
        <van-radio name="·安卓 QQ"> ·安卓 QQ</van-radio>
        <van-radio name="·安卓 VX"> ·安卓 VX</van-radio>
        <van-radio name="·微信 IOS"> ·微信 IOS</van-radio>
        <van-radio name="·Q Q IOS"> ·Q Q IOS</van-radio>-->
      </van-radio-group>
    </van-popup>

  </div>
</template>

<script>
export default {
  name: "PageView",
  computed: {

  },
  created() {
    const user = window.sessionStorage.getItem('user');
    const pwd = window.sessionStorage.getItem('pwd');
    if (user && pwd) {
      this.isLogin = true
      this.username = user
      this.password = pwd
    }
  },
  data() {
    return {
      bodyHeight: 0,
      totalSeconds: [], // 总秒数  
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervalIds: [], // 定时器ID  
      showPicker: false,

      isLogin: false,
      show: false,
      username: "",
      password: "",
      dataList: [
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 1,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP10.png'),
          title: 'SS27精英手册',
          tag: '限量',
          price: 180,
          describe: 'SS27精英手册',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 2,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP9.png'),
          title: 'SS27精英手册(豪华版)',
          tag: '限量',
          price: 288,
          describe: 'SS27精英手册(豪华版)',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 3,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP14.png'),
          title: '角色-沙丘之主',
          tag: '限量',
          price: 6888,
          describe: '角色-沙丘之主',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 4,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP16.png'),
          title: '枪械-p90沙丘之主',
          tag: '限量',
          price: 1680,
          describe: '枪械-p90沙丘之主',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 5,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP20.png'),
          title: '载具-布加迪威龙(星云粉)',
          tag: '限量',
          price: 3888,
          describe: '载具-布加迪威龙(星云粉)',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 6,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP19.png'),
          title: '载具-布加迪威龙(律动橙)',
          tag: '限量',
          price: 1888,
          describe: '载具-布加迪威龙(律动橙)',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 7,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP18.png'),
          title: '载具-布加迪威龙(金松棕)',
          tag: '限量',
          price: 1888,
          describe: '载具-布加迪威龙(金松棕)',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 8,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP17.png'),
          title: '载具-布加迪威龙(海洋蓝)',
          tag: '限量',
          price: 1888,
          describe: '载具-布加迪威龙(海洋蓝)',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 9,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP15.png'),
          title: '套装-猪猪侠',
          tag: '限量',
          price: 1280,
          describe: '套装-猪猪侠',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 10,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP13.png'),
          title: '套装-菲菲',
          tag: '限量',
          price: 1280,
          describe: '套装-菲菲',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 11,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP12.png'),
          title: '套装-超人强',
          tag: '限量',
          price: 1280,
          describe: '套装-超人强',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 12,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP3.png'),
          title: '角色-冥河战士',
          tag: '限量',
          price: 1280,
          describe: '角色-冥河战士',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 13,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP4.png'),
          title: '幸运币*243',
          tag: '限量',
          price: 288,
          describe: '幸运币*243',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 14,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP5.png'),
          title: '套装-梦幻火箭',
          tag: '限量',
          price: 280,
          describe: '套装-梦幻火箭',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 15,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP6.png'),
          title: '套装-焰龙焚天',
          tag: '限量',
          price: 380,
          describe: '套装-焰龙焚天',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 16,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP7.png'),
          title: '套装-洛天依',
          tag: '限量',
          price: 380,
          describe: '套装-洛天依',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 17,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP8.png'),
          title: '套装-哥斯拉',
          tag: '限量',
          price: 380,
          describe: '套装-哥斯拉',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 18,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP1.png'),
          title: '兑换徽章',
          tag: '限量',
          price: 380,
          describe: '可在活动兑换奖励',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 19,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP2.png'),
          title: '套装-裁决冥王',
          tag: '限量',
          price: 198,
          describe: '套装-裁决冥王，若游戏内已拥有，请勿重复购买，以免给您带来损失。',
        },
        {
          year: 2024,
          month: 6,
          day: 5,
          id: 20,
          hour: 0,
          min: 0,
          s: 0,
          imgsrc: require('../assets/images/TP11.png'),
          title: '零号机款机体服礼包*1',
          tag: '限量',
          price: 328,
          describe: '零号机款机体服礼包*1',
        },
      ]
    }
  },
  mounted() {
    this.dataList.forEach((element, i) => {
      this.startCountdown(element.year, element.month, element.day, i);
      // this.startCountdown(element.hour, element.min, element.s, i);
    });
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器，避免内存泄漏  
    if (this.intervalIds.length > 0) {
      this.intervalIds.forEach(element => {
        clearInterval(element);
      });
    }
  },
  methods: {
    calculateSecondsFromDate(year, month, day) {
      const targetDate = new Date(year, month - 1, day); // 注意：月份是从0开始的，所以要减1  
      const now = new Date();
      const diff = targetDate - now;
      return Math.round(diff / 1000); // 将毫秒转换为秒并四舍五入  
    },
    startCountdown(year, month, day, index) {
      // this.totalSeconds[index] = hours * 3600 + minutes * 60 + seconds;
      this.totalSeconds[index] = this.calculateSecondsFromDate(year, month, day)
      let intervalId = setInterval(() => {
        if (this.totalSeconds[index] > 0) {
          this.dataList[index].hour = Math.floor(this.totalSeconds[index] / 3600);
          this.dataList[index].min = Math.floor((this.totalSeconds[index] % 3600) / 60);
          this.dataList[index].s = this.totalSeconds[index] % 60;
          this.totalSeconds[index]--;
        } else {
          clearInterval(this.intervalIds[index]);
          // 这里可以添加倒计时结束后的逻辑  
          this.dataList[index].hour = 0;
          this.dataList[index].min = 0;
          this.dataList[index].s = 0;
        }
      }, 1000);
      this.intervalIds[index] = intervalId
    },
    showPopup() {

      this.show = true;
    },
    onSubmit(values) {
      this.show = false;
      this.isLogin = true;
      console.log("submit", values);
      this.username = values.user;
      this.password = values.pwd;
      location.reload()
    },
    toHome() {
      this.$router.push('/')
    },
    handleClick(item) {
      this.$router.push({
        path: '/order',
        query: { ...item, password: this.password, username: this.username },
      })
    }
  },
};
</script>
<style lang="scss" scoped>
header {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vw;
  border-bottom: 1px solid #f3f4f5;

  .header-left {
    width: 15vw;
  }

  .header-middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-middle-title {
      font-weight: bold;
    }

    .header-middle-desc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .header-middle-desc-img {
      img {
        vertical-align: middle;
      }
    }
  }

  .header-right {
    width: 15vw;
    display: flex;
    align-items: center;
  }

  img {
    width: 6vw;
  }
}

nav {
  height: 6vh;
  padding: 0 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    font-weight: bold;
  }

  .nav-right {
    img {
      width: 6vw;
    }
  }
}

.container {
  padding: 1vh 3vw;

  .card {
    padding: 3vw;
    margin-bottom: 1vh;
    background-color: #fff;

    .card-time {
      width: 100%;
      height: 5vh;
      display: flex;
      align-items: center;
      padding-left: 30vw;
      font-weight: bold;
      background-image: url(../assets/images/hibuybg1.png);
      background-size: cover;

      .btn {
        margin: 0 1vw;
        width: 6vw;
        height: 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #fff;
        background-color: black;
      }
    }

    .card-content {
      display: flex;
      margin-top: 1vh;
      justify-content: space-between;

      .content-left {
        width: 35vw;
        position: relative;

        .img-number {
          background-color: #E78476;
          color: #fff;
          padding: 2vw 4vw;
          border-radius: 5px 0 5px 0;
          position: absolute;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
        }
      }

      .content-right {
        width: 63vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 2vw;
        padding-bottom: 1vh;

        .content-right-title {
          display: flex;
          font-weight: bold;
          justify-content: space-between;
          align-items: center;

          .title-right {
            img {
              width: 6vw;
              vertical-align: middle;
            }
          }
        }

        .content-right-tag {
          width: 12vw;
          margin-top: 1vh;
          color: #FE6C12;
          background-color: #FFE9DC;
        }

        .content-right-price {
          display: flex;
          justify-content: space-between;

          .price-right {
            color: #fff;
            font-weight: bold;
            padding: 2vw;
            border-radius: 5px;
            background-color: #FE6C12;
          }
        }
      }
    }
  }
}
</style>
