<template>
  <div class="home">

    <header>
      <div class="header-left">
        <img src="../assets/images/ico-refresh.png" alt="" />
      </div>
      <div class="header-middle">
        <div class="header-middle-title">和平集市</div>
        <div class="header-middle-desc">
          <div class="header-middle-desc-text" v-if="!isLogin">
            未绑定(请去“个人中心->游戏账号绑定”)
          </div>
          <div class="header-middle-desc-text" v-else>
            当前角色 : {{ password }}{{ username }}
          </div>
          <div class="header-middle-desc-img">
            <img src="../assets/images/ico-change.png" alt="" />
          </div>
        </div>
      </div>
      <div class="header-right">
        <van-cell class="header-right-item" @click="showPopup" style="background: none; padding: 0">
          <img src="../assets/images/ico-record.png" alt="" />
        </van-cell>
        <van-popup v-model="show">
          <van-form @submit="onSubmit" style="width: 80vw">
            <div class="title" style="margin: 1vh 0; font-weight: bold">
              绑定账号
            </div>
            <van-field v-model="username" name="user" label="平台" placeholder="平台" @click="showPicker = true"
              :rules="[{ required: true, message: '请输入平台' }]">

            </van-field>
            <van-field v-model="password" type="text" name="pwd" label="ID" placeholder="ID"
              :rules="[{ rquired: true, message: '请输入ID' }]" />
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </van-popup>
        <div class="header-right-item">
          <img src="../assets/images/ico-rule.png" alt="" />
        </div>
      </div>
    </header>
    <nav>
      <div class="nav-left">全部商品</div>
      <div class="nav-right">
        <img src="../assets/images/ico-ty-list2.png" alt="" @click="toPage()" />
      </div>
    </nav>
    <div class="content">
      <div class="content-item" v-for="item in dataList" :key="item.id" @click="handleClick(item)">
        <div class="tag">{{ item.id }}</div>
        <div class="content-item-img">
          <img :src="item.imgsrc" alt="" />
        </div>
        <div class="content-item-title">{{ item.title }}</div>
        <div class="content-item-price">
          <div class="item-price-icon">
            <img src="../assets/images/ico-quan.png" alt="" />
          </div>
          <div class="item-price-number">{{ item.price }}</div>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" style="padding:20px;border-radius: 5px;">
      <van-radio-group v-model="username" @change="showPicker = false">
        <van-radio name="  微信苹果"> 微信苹果</van-radio>
        <van-radio name="  微信安卓"> 微信安卓</van-radio>
        <van-radio name="  QQ苹果"> QQ苹果</van-radio>
        <van-radio name="  QQ安卓"> QQ安卓</van-radio>
        <!-- <van-radio name="·安卓 QQ"> ·安卓 QQ</van-radio>
        <van-radio name="·安卓 VX"> ·安卓 VX</van-radio>
        <van-radio name="·微信 IOS"> ·微信 IOS</van-radio>
        <van-radio name="·Q Q IOS"> ·Q Q IOS</van-radio>-->
      </van-radio-group>
    </van-popup>

  </div>
</template>

<script>
export default {
  name: "HomeView",

  data() {
    return {
      bodyHeight: 0,
      showPicker: false,
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      isLogin: false,
      show: false,
      username: "",
      password: "",
      dataList: [
      {
          id: 1,
          title: "SS27精英手册",
          imgsrc: require("../assets/images/TP10.png"),
          price: 180,
          describe: 'SS27精英手册',
        },
        {
          id: 2,
          title: "SS27精英手册(豪华版)",
          imgsrc: require("../assets/images/TP9.png"),
          price: 288,
          describe: 'SS27精英手册(豪华版)',
        },
        {
          id: 3,
          title: "角色-沙丘之主",
          imgsrc: require("../assets/images/TP14.png"),
          price: 6888,
          describe: '角色-沙丘之主',
        },
        {
          id: 4,
          title: "枪械-p90沙丘之主",
          imgsrc: require("../assets/images/TP16.png"),
          price: 1680,
          describe: '枪械-p90沙丘之主',
        },
        {
          id: 5,
          title: "载具-布加迪威龙(星云粉)",
          imgsrc: require("../assets/images/TP20.png"),
          price: 3888,
          describe: '载具-布加迪威龙(星云粉)',
        },
        {
          id: 6,
          title: "载具-布加迪威龙(律动橙)",
          imgsrc: require("../assets/images/TP19.png"),
          price: 1888,
          describe: '载具-布加迪威龙(律动橙)',
        },
        {
          id: 7,
          title: "载具-布加迪威龙(金松棕)",
          imgsrc: require("../assets/images/TP18.png"),
          price: 1888,
          describe: '载具-布加迪威龙(金松棕)',
        },
        {
          id: 8,
          title: "载具-布加迪威龙(海洋蓝)",
          imgsrc: require("../assets/images/TP17.png"),
          price: 1888,
          describe: '载具-布加迪威龙(海洋蓝)',
        },
        {
          id: 9,
          title: "套装-猪猪侠",
          imgsrc: require("../assets/images/TP15.png"),
          price: 1280,
          describe: '套装-猪猪侠',
        },
      {
          id: 10,
          title: "套装-菲菲",
          imgsrc: require("../assets/images/TP13.png"),
          price: 1280,
          describe: '套装-菲菲',
        },
        {
          id: 11,
          title: "套装-超人强",
          imgsrc: require("../assets/images/TP12.png"),
          price: 1280,
          describe: '套装-超人强',
        },
        {
          id: 12,
          title: "角色-冥河战士",
          imgsrc: require("../assets/images/TP3.png"),
          price: 1280,
          describe: '角色-冥河战士',
        },
        {
          id: 13,
          title: "幸运币*243",
          imgsrc: require("../assets/images/TP4.png"),
          price: 288,
          describe: '幸运币*243',
        },
        {
          id: 14,
          title: "套装-梦幻火箭",
          imgsrc: require("../assets/images/TP5.png"),
          price: 280,
          describe: '套装-梦幻火箭',
        },
        {
          id: 15,
          title: "套装-焰龙焚天",
          imgsrc: require("../assets/images/TP6.png"),
          price: 380,
          describe: '套装-焰龙焚天',
        },
        {
          id: 16,
          title: "角色-洛天依",
          imgsrc: require("../assets/images/TP7.png"),
          price: 380,
          describe: '角色-洛天依',
        },
        {
          id: 17,
          title: "套装-哥斯拉",
          imgsrc: require("../assets/images/TP8.png"),
          price: 380,
          describe: '套装-哥斯拉',
        },
        {
          id: 18,
          title: "兑换徽章",
          imgsrc: require("../assets/images/TP1.png"),
          price: 380,
          describe: '可在活动兑换奖励',
        },
        {
          id: 19,
          title: "套装-裁决冥王",
          imgsrc: require("../assets/images/TP2.png"),
          price: 198,
          describe: '套装-裁决冥王',
        },
        {
          id: 20,
          title: "零号机款机体服礼包*1",
          imgsrc: require("../assets/images/TP11.png"),
          price: 198,
          describe: '零号机款机体服礼包*1',
        },
      ],
    };
  },
  created() {
    const user = window.sessionStorage.getItem('user');
    const pwd = window.sessionStorage.getItem('pwd');
    if (user && pwd && user != 'undefined') {
      this.isLogin = true
      this.username = user
      this.password = pwd
    }
  },
  mounted() {
  },
  methods: {


    showPopup() {
      this.show = true;
    },
    onSubmit(values) {
      this.show = false;
      this.isLogin = true
      window.sessionStorage.setItem('user', values.user)
      window.sessionStorage.setItem('pwd', values.pwd)
      this.username = values.user
      this.password = values.pwd
      location.reload()
    },
    toPage() {
      this.$router.push('/page')
    },
    handleClick(item) {
      this.$router.push({
        path: '/order',
        query: { ...item, password: this.password, username: this.username },
      })
    }
  },
};
</script>
<style lang="scss" scoped>
header {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vw;
  border-bottom: 1px solid #f3f4f5;

  .header-left {
    width: 15vw;
  }

  .header-middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-middle-title {
      font-weight: bold;
    }

    .header-middle-desc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .header-middle-desc-img {
      img {
        vertical-align: middle;
      }
    }
  }

  .header-right {
    width: 15vw;
    display: flex;
    align-items: center;
  }

  img {
    width: 6vw;
  }
}

nav {
  height: 6vh;
  padding: 0 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    font-weight: bold;
  }

  .nav-right {
    img {
      width: 6vw;
    }
  }
}

.content {
  padding: 0 2vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .content-item {
    position: relative;
    width: 49%;

    margin-bottom: 1vh;
    background-color: #fff;

    .tag {
      position: absolute;
      width: 9vw;
      height: 6vw;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      background-color: rgb(193, 70, 70);
    }

    .content-item-img {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .content-item-title {
      padding-left: 2vw;
      text-align: left;
      margin: 1vw;
      font-weight: bold;
    }

    .content-item-price {
      padding-left: 2vw;
      display: flex;
      margin: 4vh 0;

      .item-price-icon {
        width: 6vw;

        img {
          width: 100%;
        }
      }

      .item-price-number {
        font-size: 20px;
        color: rgb(218, 205, 22);
        font-weight: bold;
      }
    }
  }
}
</style>
